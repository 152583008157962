import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../services/API';
import { toast } from 'react-toastify';

export default function ModalCancelarResgate(props) {
  const [cancelando, setCancelando] = useState(false);

  const cancelarResgate = async () => {
    if (cancelando) return;
    
    try {
      setCancelando(true);
      const resultado = await api.post('/api/cancelarResgate', {
        identificador: props.resgate,
      });

      if (resultado) {
        toast.success(`Resgate cancelado com sucesso`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        props.onHide({ id: props.resgate, status: 3 });
      }
    } catch (error) {
      toast.error('Erro ao cancelar resgate', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } finally {
      setCancelando(false);
    }
  };

  const handleClose = () => {
    if (!cancelando) {
      props.onHide();
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      backdrop="static"
      keyboard={!cancelando}
    >
      <Modal.Header closeButton={!cancelando}>
        <Modal.Title>Cancelar Resgate</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="d-flex justify-content-center align-items-center text-center w-100 p-4">
          <span>Tem certeza que deseja cancelar esse resgate?</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          <button
            type="button"
            className="btn btn-success m-1"
            onClick={cancelarResgate}
            disabled={cancelando}
          >
            {cancelando ? 'CANCELANDO...' : 'SIM'}
          </button>
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={handleClose}
            disabled={cancelando}
          >
            NÃO
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
