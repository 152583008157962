import React from 'react';
import { Carousel, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Slider() {
  const handlePixBannerClick = (e) => {
    e.preventDefault();
    setTimeout(() => {
      window.scrollTo({
        top: 580,
        behavior: 'smooth'
      });
    }, 100);
  };

  return (
    <Container fluid="md" className="mb-5">
      <Row>
        <div className="d-none d-sm-block">
          <Carousel interval={3000}>
          <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="/loja" onClick={(e) => e.preventDefault()}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pontos-turbinados.png"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="/loja" onClick={(e) => e.preventDefault()}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pontos-turbinados.png"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="/loja" onClick={handlePixBannerClick}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pix-web.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="/loja" onClick={handlePixBannerClick}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pix-mobile.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            {/* <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="/premio/10123">
                  <img
                    className="d-block w-100"
                    src="imagens/banner-alexa-web.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="/premio/10123">
                  <img
                    className="d-block w-100"
                    src="imagens/banner-alexa-mobile.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item> */}
          </Carousel>
        </div>
        <div className="d-sm-none">
          <Carousel indicators={false} interval={3000}>
          <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="/loja" onClick={(e) => e.preventDefault()}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pontos-turbinados.png"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="/loja" onClick={(e) => e.preventDefault()}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pontos-turbinados.png"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="/loja" onClick={handlePixBannerClick}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pix-mobile.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="/loja" onClick={handlePixBannerClick}>
                  <img
                    className="d-block w-100"
                    src="imagens/banner-pix-mobile.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item>
            {/* <Carousel.Item>
              <div className="d-none d-md-flex">
                <Link to="/premio/10123">
                  <img
                    className="d-block w-100"
                    src="imagens/banner-alexa-web.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
              <div className="d-flex d-md-none">
                <Link to="/premio/10123">
                  <img
                    className="d-block w-100"
                    src="imagens/banner-alexa-mobile.jpg"
                    alt="Slider"
                  />
                </Link>
              </div>
            </Carousel.Item> */}
          </Carousel>
        </div>
      </Row>
    </Container>
  );
}
