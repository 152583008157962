import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../services/API';
import { toast } from 'react-toastify';

export default function ModalAlterarStatusResgate(props) {
  const [alterando, setAlterando] = useState(false);
  const [status, setStatus] = useState('');

  const alterarStatusResgate = async () => {
    if (alterando || !status) return;
    
    try {
      setAlterando(true);
      const resultado = await api.post('/api/alterarStatusResgate', {
        identificador: props.resgate,
        status,
      });

      if (resultado) {
        toast.success(`Status atualizado com sucesso`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        props.onHide({ id: props.resgate, status: Number(status) });
      }
    } catch (error) {
      toast.error('Erro ao alterar status do resgate', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } finally {
      setAlterando(false);
      setStatus('');
    }
  };

  const handleClose = () => {
    if (!alterando) {
      setStatus('');
      props.onHide();
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      backdrop="static"
      keyboard={!alterando}
    >
      <Modal.Header closeButton={!alterando}>
        <Modal.Title>Alterar Status Resgate</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <form className="form mw-100 mt-4 d-flex justify-content-center align-items-center">
          <select
            id="status"
            name="status"
            placeholder="Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            disabled={alterando}
          >
            <option disabled value="">
              Selecione o novo status
            </option>
            <option value="0">Não entregue</option>
            <option value="1">Entregue</option>
            <option value="2">Em trânsito</option>
          </select>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          <button
            type="button"
            className="btn btn-success m-1"
            onClick={alterarStatusResgate}
            disabled={alterando || !status}
          >
            {alterando ? 'ALTERANDO...' : 'ALTERAR'}
          </button>
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={handleClose}
            disabled={alterando}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
